import {
  UPDATE_WALLET_LOADING,
  SAVE_WALLET,
  SAVE_WALLET_DETAIL,
  SAVE_WALLET_TOP_UP_DETAIL,
  UPDATE_WALLET_TRANSACTIONS_LOADING,
  SAVE_WALLET_TRANSACTIONS,
  INITIATE_TRANSFER_LOADING,
  INITIATE_TRANSFER_SUCCESS,
  VERIFY_TRANSFER_SUCCESS,
  VERIFY_TRANSFER_FAILURE,
  VERIFY_TRANSFER_LOADING,
  VERIFY_TRANSFER_CANCEL,
  VERIFY_TRANSFER_STATUS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_START,
  RESEND_OTP_SUCCESS,
  VERIFY_TRANSFER_RESPONSE_CODE,
  INITIATE_RETRY_OTP,
  IS_WALLET_FILTER,
  GET_HISTORICAL_DATA,
  GET_HISTORICAL_DATA_STATUS,
  DOWNLOAD_WALLET_STATUS,
  LOADING_WALLET_TOPUP_ACCOUNTS,
  SAVE_WALLET_TOPUP_ACCOUNTS
} from "../actions/types";

const INIT_STATE = {
  loading: false,
  loadingTransactions: false,
  initiateTransferLoading: false,
  initiateTransferSuccess: false,
  transferLoading: false,
  transferStatus: '',
  data: {
    test: [],
    live: [],
  },
  walletDetail: {
    test: [],
    live: [],
  },
  walletTopupAccounts: {
    test: [],
    live: [],
  },
  loadingWalletTopupAccounts: false,
  topUpData: {
    test: [],
    live: [],
  },
  walletTransactionData: {
    test: [],
    live: [],
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
    },
  },
  isWalletFilter: {
    test: false,
    live: false,
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_WALLET_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case UPDATE_WALLET_TRANSACTIONS_LOADING:
      return {
        ...state,
        loadingTransactions: action.payload
      };

    case SAVE_WALLET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.environment]: action.payload.data
        }
      };

    case SAVE_WALLET_DETAIL:
      return {
        ...state,
        walletDetail: {
          [action.payload.environment]: action.payload.data
        }
      };

    case SAVE_WALLET_TRANSACTIONS:
      return {
        ...state,
        walletTransactionData: {
          [action.payload.environment]: action.payload.data
        }
      };
    case SAVE_WALLET_TOP_UP_DETAIL:
      return {
        ...state,
        topUpData: {
          [action.payload.environment]: action.payload.data
        }
      };

    case INITIATE_TRANSFER_LOADING:
      return {
        ...state,
        initiateTransferLoading: action.payload
      };

    case INITIATE_TRANSFER_SUCCESS:
      const status = action.payload && action.payload.transactionStatus;
      return {
        ...state,
        initiateTransferSuccess: status === "PENDING_AUTHORIZATION"? true : false,
        initiateTransferLoading: false,
        transferStatus: "",
        ...action.payload
      };

    case INITIATE_RETRY_OTP:
      return {
        ...state,
        transferStatus: ""
      };

    case VERIFY_TRANSFER_SUCCESS:
      return {
        ...state,
        transferSuccess: true,
        transferStatus: "SUCCESS"
      };
    case VERIFY_TRANSFER_STATUS:
      return {
        ...state,
        transferSuccess: true,
        transferStatus: action.payload
      };
    case VERIFY_TRANSFER_FAILURE:
      return {
        ...state,
        transferSuccess: false,
        transferStatus: "FAIL"
      };
    case VERIFY_TRANSFER_LOADING:
      return {
        ...state,
        transferLoading: action.payload
      };
    case VERIFY_TRANSFER_CANCEL:
      return {
        ...state,
        initiateTransferLoading: false,
        initiateTransferSuccess: false
      };
    case RESEND_OTP_START: {
      return {
        ...state,
        resendOTP: "START"
      };
    }
    case RESEND_OTP_SUCCESS: {
      return {
        ...state,
        resendOTP: "SUCCESS"
      };
    }
    case RESEND_OTP_FAILURE: {
      return {
        ...state,
        resendOTP: "FAIL"
      };
    }
    case VERIFY_TRANSFER_RESPONSE_CODE: {
      return {
        ...state,
        OTPResponseCode: action.payload
      };
    }

    case IS_WALLET_FILTER: {
      let isWalletFilter = state.isWalletFilter;
      isWalletFilter[action.payload.environment] = action.payload;
      return {
        ...state,
        isWalletFilter: isWalletFilter
      };
    }
    case GET_HISTORICAL_DATA:
      return {
        ...state,
        historicalData: action.payload.data
      };
    case GET_HISTORICAL_DATA_STATUS:
      return {
        ...state,
        historicalDataStatus: action.payload
      };
    case DOWNLOAD_WALLET_STATUS:
      return {
        ...state,
        downloadStatus: action.payload
      };
    case LOADING_WALLET_TOPUP_ACCOUNTS: 
      return {...state, loadingWalletTopupAccounts: action.payload}
    case SAVE_WALLET_TOPUP_ACCOUNTS:
      return {...state,  walletTopupAccounts: {
        ...state.walletTopupAccounts,
          [action.payload.environment]: action.payload.data
        }
      }
    default:
      return state;
  }
};
