import { AnalyticsBrowser } from "@customerio/cdp-analytics-browser";
import config from "../../config";

const { WRITE_KEY, SITE_ID, CDN_URL } = config?.customerIO;

export const customerIO = AnalyticsBrowser.load(
  {
    cdnURL: CDN_URL,
    writeKey: WRITE_KEY,
  },
  {
    integrations: {
      "Customer.io In-App Plugin": {
        siteId: SITE_ID,
      },
    },
  }
);
