import forge from "node-forge";
import config from "../../config";
class CryptoUtil {
  static async sha256(input) {
    try {
      const hashBuffer = await crypto.subtle.digest(
        "SHA-256",
        new TextEncoder().encode(input),
      );
      return this.base64UrlEncode(hashBuffer);
    } catch (error) {
      console.error("Error in sha256:", error);
      throw error;
    }
  }

  static base64UrlEncode(arrayBuffer) {
    // Convert ArrayBuffer to a string with Base64 encoding
    const base64String = btoa(
      String.fromCharCode(...new Uint8Array(arrayBuffer)),
    );

    // Convert to Base64 URL-encoded format
    return base64String
      .replace(/\+/g, "-") // Replace + with -
      .replace(/\//g, "_") // Replace / with _
      .replace(/=+$/, ""); // Remove any = padding
  }

  static generateCodeVerifier() {
    const validChars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-";
    const validCharsLength = validChars.length;
    const uuidLength = 128;
    const uuidArray = new Array(uuidLength);
    const randomValues = new Uint8Array(uuidLength);

    crypto.getRandomValues(randomValues);

    for (let i = 0; i < uuidLength; i++) {
      uuidArray[i] = validChars.charAt(randomValues[i] % validCharsLength);
    }
    return uuidArray.join("");
  }

  static encryptWithRSA(data) {
    const base64Key = config.MFA_BASE64_PUBLIC_KEY;
    if (!base64Key) throw new Error("No public key found");

    const publicKeyPem = this.base64ToPem(base64Key);
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const encrypted = publicKey.encrypt(data, "RSAES-PKCS1-V1_5");

    return forge.util.encode64(encrypted);
  }

  static base64ToPem(base64Key) {
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";

    // Insert line breaks every 64 characters to comply with PEM format
    const formattedKey = base64Key.match(/.{1,64}/g).join("\n");

    // Return the full PEM-formatted key
    return `${pemHeader}\n${formattedKey}\n${pemFooter}`;
  }
}

export default CryptoUtil;