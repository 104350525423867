// import * as Currency from "currency.js";
import {
  lowerCase,
  capitalize,
  includes,
  isEmpty,
  intersection,
  isObject,
} from "lodash";
import moment from "moment";
import { Tag, message } from "antd";
import React from "react";
import dataStore from "../dataStore";
import { DATA_STORE_KEYS } from "../dataStore/keys";
import queryString from "query-string";
import { URL_LINK_REGEX, currencyCodes } from "../constants";

const Currency = require("currency.js");

let downloadFile = require("js-file-download");

class Util {
  static disablePastDates = (current) => {
    const currentDate = new Date();
    return current && current < currentDate.setHours(0, 0, 0, 0);
  };

  static convertDateToString = (dateValue) => {
    return new Date(dateValue).toISOString().split("T")[0];
  };

  static nFormatter = (num, digits) => {
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  };
  static isDevelopmentMode = () => {
    return process.env.NODE_ENV === "development";
  };

  static formatNumber = (number) => {
    return number.toLocaleString();
  };

  static formatAmount = (symbol, amount) => {
    if (amount === undefined || amount === null) {
      return "₦ -.--";
    }

    if (currencyCodes[symbol]) {
      symbol = currencyCodes[symbol];
    } else {
      symbol = currencyCodes.NGN;
    }
    symbol = symbol + "";

    let currencyOption = {
      symbol: symbol,
    };

    // let currency = currency(amount, currencyOption);
    return Currency(amount, currencyOption).format(true);
  };

  static getRouteQueryParams(searchString) {
    return queryString.parse(searchString);
  }

  static getGeneralStatusTagClass = (status) => {
    status = status && lowerCase(status);

    switch (status && status.trim()) {
      case "one time":
      case "single":
        return "one-time";
      case "subscription":
        return "subscription";
      case "success":
      case "paid":
      case "credit":
      case "active":
      case "fixed":
      case "overpaid": {
        return "success";
      }
      case "debit":
      case "failed": {
        return "failed";
      }
      case "underpaid":
      case "invoice":
      case "pending": {
        return "pending";
      }

      case "cancelled":
        return "cancelled";
      default: {
        return "unknown";
      }
    }
  };

  static getRefundStatusTagClass = (status) => {
    status = status && lowerCase(status);

    switch (status) {
      case "full refund": {
        return "blue-tag";
      }
      case "partial refund":
      default: {
        return "normal-tag";
      }
    }
  };

  static getPaymentPagesTag = (status) => {
    status = status && lowerCase(status);
    let activeTag = "rgba(30, 177, 45, 0.1)";
    let inactiveTag = "rgba(6, 58, 79, 0.1)";
    let paidTag = "rgba(30, 177, 45, 0.1)";
    let pendingTag = "rgba(240, 137, 34, 0.1)";

    switch (status) {
      case "active": {
        return activeTag;
      }
      case "paid": {
        return paidTag;
      }
      case "pending": {
        return pendingTag;
      }
      case "inactive":
      default: {
        return inactiveTag;
      }
    }
  };

  static getPaymentPagesTagStyle = (status) => {
    status = status && lowerCase(status);
    let generalStyle = {
      fontWeight: "700",
      fontSize: "10px",
    };
    let activeTagStyle = {
      color: "rgba(30, 177, 45, 1)",
      ...generalStyle,
    };
    let inactiveTagStyle = {
      color: "rgba(11, 50, 117, 0.4)",
      ...generalStyle,
    };
    let paidTagStyle = {
      color: "#1EB12D",
      ...generalStyle,
    };
    let pendingTagStyle = {
      color: "#F08922",
      ...generalStyle,
    };

    switch (status) {
      case "active": {
        return activeTagStyle;
      }
      case "paid": {
        return paidTagStyle;
      }
      case "pending": {
        return pendingTagStyle;
      }
      case "inactive":
      default: {
        return inactiveTagStyle;
      }
    }
  };

  static getPaycodeStatusClass = (status) => {
    status = status && lowerCase(status);

    switch (status) {
      case "success": {
        return "success";
      }
      case "cancelled":
      case "failed": {
        return "failed";
      }
      case "expired":
        return "default";

      case "pending": {
        return "pending";
      }
      default: {
        return "unknown";
      }
    }
  };

  static nairaPrefix = (
    <div
      style={{ color: "rgba(46, 56, 77, 0.34)", background: "#fff !important" }}
    >
      ₦
    </div>
  );

  static getTransactionRefundStatusClass = (paymentStatus) => {
    paymentStatus = paymentStatus && lowerCase(paymentStatus);

    switch (paymentStatus) {
      case "FULL_REFUND": {
        return "success";
      }
      case "PARTIAL_REFUND":
      default: {
        return "unknown";
      }
    }
  };

  static isTransactionRefundable = (paymentStatus) => {
    return ["paid", "overpaid", "partially refunded", "completed"].includes(
      lowerCase(paymentStatus),
    );
  };

  static getTransactionStatusClass = (paymentStatus) => {
    paymentStatus = paymentStatus && lowerCase(paymentStatus);
    switch (paymentStatus) {
      case "success":
      case "sent":
      case "paid":
      case "credit":
      case "successful":
      case "completed":
      case "approved":
      case "beneficiary settled":
      case "submitted":
      case "overpaid": {
        return "success";
      }
      case "sub account":
        return "sub-account";
      case "merchant":
        return "merchant";
      case "debit":
      case "rejected":
      case "cancelled":
      case "below minimum refund amount":
      case "invalid settlement amount":
      case "invalid account details":
      case "customer payout failed":
      case "invalid refund amount":
      case "failed": {
        return "failed";
      }
      case "underpaid":
      case "processing":
      case "in_progress":
      case "pending": {
        return "pending";
      }
      case "merchant contract":
        return "default bold-text";
      case "refunded":
      case "partially refunded": {
        return "default";
      }
      case "reversed": {
        return "reversed"
      }

      default: {
        return "unknown";
      }
    }
  };

  static getKycVerificationStatusClass = (status) => {
    status = status && lowerCase(status);

    switch (status) {
      case "completed":
      case "success": {
        return "kyc-ver-success";
      }
      case "pending": {
        return "kyc-ver-pending";
      }
      case "rejected": {
        return "kyc-ver-failed";
      }
      default: {
        return "kyc-ver-pending";
      }
    }
  };

  static normalizePaymentStatus = (paymentStatus) => {
    paymentStatus = paymentStatus && paymentStatus.replaceAll("_", " ");
    return Util.capitalizeStatus(paymentStatus);
  };

  static normalizePaymentMethod = (paymentMethod) => {
    paymentMethod = paymentMethod && paymentMethod.replaceAll("_", " ");
    return capitalize(paymentMethod);
  };

  static getRejectedPaymentStatus = (status) => {
    switch (status.trim().toLowerCase()) {
      case "completed":
        return (
          <Tag
            className={`transaction-status-tag ${Util.getTransactionStatusClass(
              status,
            )}`}
          >
            Completed
          </Tag>
        );

      case "funding_customer_account":
      case "pending":
      case "funding_refund_payable_account":
      case "validating_account_details":
        return (
          <Tag
            className={`transaction-status-tag ${Util.getTransactionStatusClass(
              status,
            )}`}
          >
            In Progress
          </Tag>
        );
      case "customer_payout_failed":
      case "invalid_refund_amount":
      case "invalid account details":
      case "invalid_account_details":
      case "below_minimum_refund_amount":
      case "invalid_settlement_amount":
        return (
          <Tag
            className={`transaction-status-tag ${Util.getTransactionStatusClass(
              status,
            )}`}
          >
            Blocked
          </Tag>
        );
      default:
        return "";
    }
  };

  static getRejectedPaymentStatusName = (status) => {
    switch (status.trim().toLowerCase()) {
      case "completed":
      case "below_minimum_refund_amount":
      case "invalid_settlement_amount":
        return "Completed";
      case "funding_customer_account":
      case "pending":
      case "funding_refund_payable_account":
      case "validating_account_details":
        return "In Progress";
      case "customer_payout_failed":
      case "invalid_refund_amount":
      case "invalid account details":
      case "invalid_account_details":
        return "Blocked";
      default:
        return "";
    }
  };

  static getPaymentMethodBadge = (paymentMethod) => {
    return (
      (paymentMethod && (
        <Tag className="payment-method-tag">
          {Util.normalizePaymentMethod(paymentMethod)}
        </Tag>
      )) ||
      ""
    );
  };

  static isStringUrl = (payload) => {
    if (!payload) return false;

    if (isObject(payload)) return false;

    var expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
    var regex = new RegExp(expression);
    if (payload.match(regex)) {
      return true;
    }
    return false;
  };

  static validatePageDescriptionMaxLength = (rule, value, callback) => {
    if (!value || value.length > 225) {
      return callback("Maximum allowed length is 225");
    }

    callback();
  };

  static getGeneralStatusBadge = (paymentStatus) => {
    return (
      <Tag
        className={`transaction-status-tag ${Util.getGeneralStatusTagClass(
          paymentStatus,
        )}`}
      >
        {Util.normalizePaymentStatus(paymentStatus)}
      </Tag>
    );
  };

  static getPaymentStatusBadge = (paymentStatus) => {
    return (
      <Tag
        className={`transaction-status-tag ${Util.getTransactionStatusClass(
          paymentStatus
        )}`}
      >
        {Util.normalizePaymentStatus(paymentStatus)}
      </Tag>
    );
  };

  static getRefundTypeBadge = (paymentStatus) => {
    return (
      <Tag
        className={`transaction-status-tag ${Util.getTransactionStatusClass(
          paymentStatus,
        )}`}
      >
        {Util.normalizePaymentStatus(paymentStatus)}
      </Tag>
    );
  };

  static getPaycodeStatusBadge = (paymentStatus) => {
    return (
      <Tag
        className={`transaction-status-tag ${Util.getPaycodeStatusClass(
          paymentStatus,
        )}`}
      >
        {Util.normalizePaymentStatus(paymentStatus)}
      </Tag>
    );
  };

  static formatDateTime = (timestamp) => {
    let momentTime = moment(timestamp);
    return momentTime.format("MMMM Do YYYY, h:mm:ss a");
  };

  static formatDateTimeFromDateString = (dateString) => {
    return moment(dateString, "DD/MM/YYYY h:mm:ss a").format(
      "MMMM Do YYYY, h:mm:ss a",
    );
  };

  static formatDateTimeFromDateStringShort = (dateString) => {
    return moment(dateString, "DD/MM/YYYY h:mm:ss a").format(
      "MMM Do YYYY, h:mma",
    );
  };

  static convertPageResponseToTableData = (response) => {
    const { responseBody } = response.data;
    const current = responseBody.number + 1;
    const { size, totalElements } = responseBody;

    return {
      data: responseBody.content,
      pagination: {
        current: current,
        pageSize: size,
        showSizeChanger: true,
        total: totalElements,
        pageSizeOptions: ["20", "30", "40", "50", "100"],
      },
    };
  };

  static convertPageResponseToTableDataPaginated = (response) => {
    const { responseBody } = response.data;
    const paginatedTransactions = responseBody.paginatedTransactions || {};
    const current = paginatedTransactions.number + 1;
    const { size, totalElements } = paginatedTransactions;

    return {
      data: paginatedTransactions.content,
      pagination: {
        current: current,
        pageSize: size,
        showSizeChanger: true,
        total: totalElements,
        pageSizeOptions: ["20", "30", "40", "50", "100"],
      },
    };
  };

  static formHasErrors(fieldsError) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  }

  static validatePasswordStrength = (password) => {
    if (!password || password.length < 8) {
      return "Password must be at leat 8 characters.";
    }

    let conditions = [];
    conditions.push({
      reg: "[$@$!%*#?&]",
      message: "Password must have at least one special character.",
    }); // Special Charector
    conditions.push({
      reg: "[A-Z]",
      message: "Password must have at least one uppercase character.",
    }); // Uppercase Alpabates
    conditions.push({
      reg: "[0-9]",
      message: "Password must have at least one number.",
    }); // Numbers
    conditions.push({
      reg: "[a-z]",
      message: "Password must have at least one lowercase character.",
    });

    for (var i = 0; i < conditions.length; i++) {
      let condition = conditions[i];

      const conditionMatches = new RegExp(condition.reg).test(password);
      if (!conditionMatches) {
        return condition.message;
      }
    }

    return undefined;
  };

  static legacyFileDownload = (data, filename) => {
    return downloadFile(data, filename);
  };

  static fileDownload = (data, filename, mime, isBlob = false) => {
    let blobData = [data];
    let blob = isBlob
      ? data
      : new Blob(blobData, { type: mime || "application/octet-stream" });
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      // IE workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
    } else {
      let blobURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = blobURL;
      tempLink.setAttribute("download", filename);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }

      document.body.appendChild(tempLink);
      tempLink.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        }),
      );
      document.body.removeChild(tempLink);
      // window.URL.revokeObjectURL(blobURL);
    }
  };

  static getFileNameAndExtension(file) {
    let _file = {};

    if (!file || !file.name) {
      return;
    }

    const name = file.name;
    const lastDot = name.lastIndexOf(".");

    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);

    _file.value = fileName;
    _file.extension = ext.toUpperCase();

    return _file;
  }

  static getParams = (url) => {
    let params = {};
    let parser = document.createElement("a");
    parser.href = url;
    let query = parser.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };

  static convertUnderScoreCaseToWords = (string) => {
    let newString = string.toLowerCase().replace(new RegExp("_", "g"), " ");
    newString = newString.charAt(0).toUpperCase() + newString.slice(1);
    return newString;
  };

  static validatePhoneNumber = (rule, value, callback, updateField) => {
    if (!value) {
      callback();
      return;
    }

    if (value.match(/[^$,.\d]/)) {
      callback("Please enter a valid phone number. 11 digits required");
    }

    let valid = /\d{10}/.test(value);
    if (valid) {
      callback();
    } else {
      callback("Please enter a valid phone number");
    }
  };

  static validateWebsite = (rule, value, callback) => {
    // eslint-disable-next-line no-useless-escape
    let validator = URL_LINK_REGEX;

    // /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gim;

    if (!value) {
      callback();
      return;
    }

    if (!value.match(validator)) {
      callback("Please provide notification URL");
      return;
    }

    callback();
  };

  static isWebsiteUrlValid = (value) => {
    // eslint-disable-next-line no-useless-escape
    let validator =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gim;

    if (!value || value.trim().length === 0) {
      return false;
    }

    return value.match(validator);
  };

  static isValidEmail = (value) => {
    // eslint-disable-next-line no-useless-escape
    // let validator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let validator = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,7}$/g;

    return value.match(validator);
  };

  static validateIPAddress(rule, value, callback) {
    let validator =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

    if (!value) {
      callback();
      return;
    }

    if (!value.match(validator)) {
      callback("Please specify a valid ip address");
      return;
    }

    callback();
  }

  static getBankName = (code, banks) => {
    return banks && banks.find((bank) => bank.code === code);
  };

  static formatBankDetails = (record, banks) => {
    let bank = Util.getBankName(record.bankCode, banks);
    return `${record.accountNumber}  ( ${bank && bank.name} )`;
  };

  static formatText = (text, split) => {
    if (!text) return;
    let formattedText = text.trim();
    if (split) {
      formattedText = formattedText
        .split(split)
        .map((word) => capitalize(word))
        .join(" ");
    } else {
      formattedText = capitalize(text);
    }

    return formattedText;
  };

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  };

  static capitalizeStatus = (s, separator) => {
    if (typeof s !== "string") return "";

    //s = s && s.split("_").join(" ");
    const words = s && s.split(separator || "_");

    s = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return s;
  };

  static formatedDateFromSearchParams = ({ from, to }) => {
    let formatedDateRange = "";
    if (from) {
      formatedDateRange += moment(from, "x").format("YYYYMMDD");
    }

    if (to) {
      formatedDateRange += "-" + moment(to, "x").format("YYYYMMDD");
    }

    if (formatedDateRange === "") {
      formatedDateRange += moment().format("YYYYMMDD");
    }

    return formatedDateRange;
  };

  static canAccessFeature = (requiredFeatureToggleKey) => {
    const bussiness = dataStore.get(DATA_STORE_KEYS.ACTIVE_BUSINESS);

    if (!bussiness) {
      return true;
    }

    if (!requiredFeatureToggleKey) {
      return true;
    }

    if (Array.isArray(requiredFeatureToggleKey)) {
      return (
        intersection(
          bussiness.merchantAllowedFeatures,
          requiredFeatureToggleKey,
        ).length > 0
      );
    }

    return includes(
      bussiness.merchantAllowedFeatures,
      requiredFeatureToggleKey,
    );
  };

  static extensionMapper = {
    png: "image/png",
    jpg: "image/jpg",
    jpeg: "image/jpeg",
    pdf: "application/pdf",
  };

  static parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    const result = JSON.parse(jsonPayload);
    return result;
  };

  static updatePathWithRequestParams = (requestParams, pathName, history) => {
    const queryString =
      "?" + Util.getRequestStringFromSearchParams(requestParams);
    history.push({
      pathname: pathName,
      search: queryString,
    });
  };

  static getRequestStringFromSearchParams = (requestParams) => {
    let queryString = "";

    if (!requestParams["page"]) {
      queryString += "page=0";
    }
    if (!requestParams["results"] && !requestParams["size"]) {
      queryString += "&size=10";
    }

    for (const key in requestParams) {
      if (requestParams[key] && `${requestParams[key]}`.split(",").length > 1) {
        const values = requestParams[key].split(",");
        for (let i = 0; i < values.length; i++) {
          queryString += `&${key}=${values[i]}`;
        }
      } else if (requestParams[key]) {
        let value = encodeURIComponent(requestParams[key]);
        queryString += `&${key}=${value}`;
      }
    }

    return queryString;
  };

  static getParamsFromSearchParams = (searchParams) => {
    const urlSearchParams = new URLSearchParams(searchParams);
    return Object.fromEntries(urlSearchParams.entries());
  };

  static STATUS_MAP = {
    pending: "kyc_pending",
    success: "kyc_success",
    rejected: "kyc_failed",
    default: "kyc_default",
  };

  static isDisabled = (form) => {
    if (form) {
      const fieldsError = form.getFieldsError();
      const fields = Object.keys(fieldsError);
      return !fields.some(
        (field) => form.isFieldTouched(`${field}`) && !fieldsError[field],
      );
    }
  };

  static isFormInvalid = (form) => {
    if (form) {
      const fieldsError = form.getFieldsError();
      const fields = Object.keys(fieldsError);
      return !fields.some((field) => !fieldsError[field]);
    }
  };

  static truncateString = (string, num = 16, start = 0) => {
    if (string && string.length > num) {
      if (start && string.length > start && start < num) {
        let value = string.slice(start, num);
        return string.replace(value, "...");
      }
      return string.slice(0, num) + "...";
    }

    return string;
  };

  static validateFileType = (
    fileName = "",
    fileTypes = [""],
    sendMessage = false,
  ) => {
    if (fileName && fileTypes.length) {
      let fileType = fileName.split(".");
      fileType = fileType[fileType.length - 1].toLowerCase();

      const isValid = fileTypes.indexOf(fileType);

      if (!~isValid) {
        sendMessage &&
          message.error(`Please upload only ${fileTypes.join("/")} file`);
        return false;
      }

      return true;
    }
  };

  static getReferenceFromUrl = (location) => {
    let urlArray = location.pathname.split("/");
    return urlArray[urlArray.length - 1];
  };

  static validateFileSize = (
    fileSize,
    maxFileSize = 2,
    sendMessage = false,
    uploadType = "Image",
  ) => {
    if (fileSize) {
      const isValid = fileSize / 1024 / 1024 < maxFileSize;

      if (!isValid) {
        sendMessage &&
          message.error(
            `${uploadType} size exceeds the maximum allowed size of ${maxFileSize}MB`,
          );
        return false;
      }

      return true;
    }
  };

  static hasAuthority = (auth) => {
    const authorities = dataStore.get(DATA_STORE_KEYS.AUTHORITIES);
    return includes(authorities, auth) ? true : false;
  };
  static camelCaseTotitleCase = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };
  static getDateRangeValues = (values) => {
    let rangeValue = values?.from
      ? [moment(new Date(+values.from)), moment(new Date(+values.to))]
      : values?.from === null
      ? []
      : null;

    return rangeValue;
  };

  static normalizeMobileNumber = (mobileNumber) => {
    const mobileLength = mobileNumber.length;
    if (mobileLength === 11) {
      return mobileNumber.slice(1, 11);
    } else if (mobileNumber[0] === "+") {
      return mobileNumber.slice(4, mobileLength);
    }
    return mobileNumber;
  };

}

export const DEFAULT_PRE_VERIFIED_HOME_ROUTE = "/activate-business";
export default Util;
